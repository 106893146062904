@import "./variables";

.input-content {
  position: relative;
  height: 56px;
  &.invalid,
  &.filled-error {
    input {
      border: 2px solid $color-crimson-red;
      color: $color-crimson-red;
      &:focus {
        border-color: $color-crimson-red;
        ~ .floating-label {
          color: $color-crimson-red;
        }
      }
    }
    .floating-label {
      font-weight: 600;
      color: $color-crimson-red;
    }
  }
  &.invalid {
    .error-message {
      color: $color-crimson-red;
      display: block;
    }
  }
  &.filled-error {
    .error-icon {
      display: block;
      position: absolute;
      right: 10px;
      top: 6px;
      svg-icon svg path {
        stroke: $color-crimson-red;
      }
    }
  }

  .error-message,
  .error-icon {
    display: none;
  }

  .error-message {
    font-size: 10px;
    padding-left: 15px;
    padding-top: 5px;
  }

  .floating-label {
    color: $color-text-gray;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 10px;
    top: 18px;
    padding: 0 5px;
    background: #fff;
    transition: 0.2s ease all;
  }
  input {
    font-size: 16px;
    display: block;
    width: 100%;
    height: 100%;
    background: #fff;
    color: #323840;
    border: 1px solid $color-light-grey;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 20px 16px 6px;
    &:focus {
      outline: none;
      border-color: $color-blue;
      ~ .floating-label {
        font-size: 12px;
        top: -8px;
        color: $color-blue;
      }
    }
    &:not(:placeholder-shown) ~ .floating-label {
      top: -8px;
    }
  }
}
