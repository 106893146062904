@import 'src/styles/variables';

.modal-content{
  border-radius: 16px ;
  border: 1px solid $color-light ;
  .close{
    &:focus{
      outline: none;
    }
    .close-icon{
      background: url("#{$image-path}close.svg") center center no-repeat;
    }
  }

  .modal-title {
    font-weight: $font-weight-normal;
    font-size: $font-size-lg;
    color: $color-text-light;
  }
  .modal-body {
    .confirm-message {
      font-weight: $font-weight-normal;
      font-size: $font-size-sm;
      color: $color-text-light;
      padding: 30px 0;
    }
  }
}

.outbound-modal {
  .modal-content {
    border-radius: 4px ;
  }
}

.observe-modal {
  .modal-dialog {
    max-width: calc(100vw - 60px);
  }
  .modal-content {
    border: none ;
    background: transparent;
  }
}

.large-modal{
  .modal-dialog {
    max-width: 1024px;
  }
}

.settings-modal {
  .modal-content {
    border-radius: 4px;
    background-color: $color-cobalt;
    border-color: $color-cobalt;
  }
  @media screen and (orientation: landscape) {
    .modal-dialog {
      width: calc(100vw - 50px);
      max-width: 750px;
    }
  }
}

.ImageModalClass {
  .modal-dialog{
    min-width: 80%;
    @media (max-width: 414px) {
      .modal-content{
        border-radius: 0;
      }
    }
  }
}

.confirmAppointmentModalClass{
  .modal-content{
    border-radius: 0;
    border-style: none;
  }
}

.AssignAgentListModalClass{
  .modal-dialog{
    max-height: calc(100vh - 35px);
  }
}

.invite-modal {
  .modal-content {
    border-radius: 4px;
    background-color: $color-cobalt;
    border-color: $color-cobalt;
  }
}

.fcfs-modal {
  .modal-content {
    border-radius: 0;
    background-color: $color-cobalt;
    border: none;
    color: $color-light;
    padding: 12px;
    .modal-body, .modal-footer {
      border: none;
      button {
        background-color: transparent;
        border: none;
        color: $color-light;
        outline: none;
      }
    }
  }
}

.co-browse-modal{
  .modal-dialog{
    margin: 8px;
    max-width: none;
    .modal-content{
      height: calc(100vh - 16px);
      border-radius: inherit;
    }
  }
}

.translate-modal, .translate-modal-mobile, .inventory-modal, .licenseId-modal {
  .modal-dialog {
    .modal-content {
      border-radius: inherit;
    }
  }
}

.licenseId-modal {
  .modal-content {
    border: 2px solid $color-offline-red;
  }
}
.translate-modal-mobile {
  .translate-modal-div {
    background-color: $color-text-light;
    color: $color-light;
    .close-btn {
      background: $color-light !important;
      border: 1px solid $color-light !important;
    }
  }
}

.join-call-modal{
  .modal-content{
    width: 375px;
  }
}
