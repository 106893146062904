@import 'src/styles/variables';

#mobile-in-call {
  .participant-container {
    .participant-row {
      .participant {
        position: relative;
        width: 62px;
        height: 62px;
        border-radius: 50%;
        margin-right: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color: $color-text-light;
        border: 1px solid $color-light;
        cursor: pointer;
        &::after {
          position: absolute;
          width: 100%;
          height: 100%;
          content: "";
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        &::before {
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
          content: attr(data-identity);
          color: $color-light;
          padding: 0 10px;
          top: 78px;
          width: 75px;
          height:24px;
          border: 1px solid $color-light;
          background-color: #0000004d;
          border-radius: 32px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-transform: capitalize;
          text-align: center;
          z-index: 1;
        }
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }

        &.guest:not(.maximizedParticipant) {
          &.mic-off {
            &:not(.video-off) {
              &::after {
                background-color: #0000004d;
                background-image: url('#{$image-path}mic-off-icon.svg');
                background-position: center;
                background-repeat: no-repeat;
                left: 50%;
                top: 50%;
                background-size: 50%;
                border-radius: 50%;
              }
            }
          }
        }
        &.agent {
          &.mic-off:not(.video-off) {
            &::after {
              background-color: #0000004d;
              background-image: url('#{$image-path}mic-off-icon.svg');
              background-position: center;
              background-repeat: no-repeat;
              left: 50%;
              top: 50%;
              background-size: 50%;
              border-radius: 50%;
            }
          }
          &.video-off {
            background-image: url('#{$image-path}user.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 50%;
          }
        }
        &.guest {
          &:first-child {
            background-color: $color-cobalt;
          }
          &:nth-child(2) {
            background-color: $color-tint-violet;
          }
          &:nth-child(3) {
            background-color: $color-tint-green;
          }
          &.video-off:not(.mic-off) {
            &::after {
              background-image: url('#{$new-image-path}audio-on.svg');
              background-position: center;
              background-repeat: no-repeat;
              left: 50%;
              top: 50%;
              background-size: 50%;
              border-radius: 50%;
            }
          }
        }
        &.maximizedParticipant {
          width: 100vw;
          height: calc(var(--app-height));
          bottom: 0;
          right: 0;
          border-radius: 0;
          border: none;
          transition: 1s;
          position: absolute;
          z-index: -1;
          margin: 0;
          video {
            border-radius: 0;
          }
          &::before {
            width: auto;
          }
          &.mic-off {
            &.agent, &.guest {
              &::after {
                background-color: #0000004d;
                background-image: url('#{$image-path}mic-off-icon.svg');
                background-position: center;
                background-repeat: no-repeat;
                position: absolute;
                left: 50%;
                top: 65%;
                content: "";
                height: 60px;
                width: 60px;
                background-size: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                border: 1px solid $color-light;
              }
            }
          }
        }
      }
      &.click-disabled{
        .agent, .guest {
          cursor: not-allowed;
          &.maximizedParticipant {
            &::before {
              opacity: 0;
            }
            &::after {
              opacity: 0;
            }
          }
        }
      }
    }
  }
  .call-header {
    .end-popover {
      background-color: $color-dark;
      color: $color-light;
      padding: 16px;
      border: 1px solid $color-text-gray;
      border-radius: 0;
      .popover-body {
        padding: 0;
        border-radius: 0;
      }
      .arrow {
        display: none;
      }
      button {
        font-weight: $font-weight-semi-bold;
      }
    }
  }
  .chat-div {
    .chat-container {
      height: 100%;
      background-color: transparent;
      padding: 16px;
      &__message {
        height: calc(100% - 62px);
        padding: 0 16px;
        .typing-info {
          // bottom: 62px;
          background-color: transparent;
          color: $color-light;
        }
      }
      &__input {
        padding: 16px 0;
        width: calc(100% - 32px);
        form {
          padding: 0;
        }
      }
    }
  }
  .controls-container {
    .overflow-popover {
      background-color: $color-text-light;
      border-radius: 0;
      width: 220px;
      padding: 0;
      box-shadow: 0 8px 24px rgb(0 0 0 / 15%);
      border: 1px solid $color-text-gray;
      .popover-body {
        padding: 0;
        border-radius: 0;
      }
      button {
        padding: 10px;
        border-radius: 0;
        width: 100%;
        font-weight: $font-weight-normal;
        border-bottom: 1px solid $color-border-gray;
        &:last-child {
          border-bottom: none;
        }
      }
      .arrow {
        display: none;
      }
      .count{
        padding: 1px 8px;
        border-radius: 4px;
        background-color: $color-light;
        color: $color-text-light;
      }
    }
    .overflow-menu {
      &.open {
        .control-button {
          .counter {
            visibility: hidden;
          }
        }
      }
    }
    .switch-video {
      .control-button {
        .counter {
          background-color: $color-crimson-red;
          border-radius: 50%;
          color: $color-light;
          border: 1px solid $color-light;
        }
      }
    }
  }
  &.is-landScape {
    .controls-container {
      width: 82px;
      height: 100%;
      flex-direction: column;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
      .control-button {
        .mic-cam-error {
          left: -305px;
          top: 0;
          bottom: unset;
          .arrow {
            left: 284px;
            transform: rotate(134deg);
            -webkit-transform: rotate(134deg);
            top: 18px;
          }
        }
      }
    }
    .participant-container {
      .participant-row {
        .participant {
          margin:16px 0 30px;
          &.maximizedParticipant {
            margin: 0;
          }
        }
      }
    }
  }
}
.call-mobile-chat {
  .show-trans {
    bottom: -42px !important;
  }
}
