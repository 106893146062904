/* You can add global styles to this file, and also import other style files */
//@import '../node_modules/bootstrap/dist/css/bootstrap.css';
//design system
@import 'styles/variables';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import '@ctrl/ngx-emoji-mart/picker';

@import 'styles/buttons';
@import 'styles/inputs.scss';
@import 'styles/main';
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');


:root {
  --button-background-color: #f04e23;
  --button-foreground-color: #ffffff;
  --button-border-color: transparent;
  --highlighted-text-color: #f04e23;
  --app-height: 100%;
}

html, body { height: 100%; }
body { margin: 0; font-family: 'Roboto', sans-serif; }
