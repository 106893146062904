// NOTE: 10px = 1rem
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-amaranth: 'Amaranth', sans-serif;
$font-family-PT: 'PT', sans-serif;
$font-family-doppio: 'Doppio One';
$font-family-montserrat: 'Montserrat';
$enable-responsive-font-sizes: true;

$highlighted-text-color: var(--highlighted-text-color);
$button-foreground-color: var(--button-foreground-color);
$button-border-color: var(--button-border-color);
$button-background-color: var(--button-background-color);

$client-color: var(--client-color);
$button-color: var(--button-color);

//color-primary COLORS
$color-text-dark: #202040;
$color-text-light: #2C3E50;
$color-text-red: #CB3530;
$color-crimson-red: #CB2530;
$color-light-gray: #F2F2F2;
$color-bg-dark: #303030;
$color-text-black: #4D4D66;
$color-bg-gray: #ECF0F1;
$color-text-gray: #7F8C8D;
$color-dark-gray: #34495E;
$color-cobrowse-gray: #f7f7f7;
$color-light-border-gray: #ACC2CE;

$color-prime-blue: #2980B9;
$color-prime-green: #15967D;
$color-prime-purple: #9B59B6;
$color-prime-yellow: #F39C12;
$color-prime-black: #303030;

$color-red-tint: #FADBD8;
$color-red-dark-tint: #F1839D;
$color-blue-tint: #9DD5DF;
$color-red-shade: #B92649;
$color-brand-blue: #0A97B0;
$color-brand-red: #E7305B;
$color-brand-red-tint: #EC7063;
$color-prime-orange: #F04E23;

$color-blue: #3498DB;
$color-blue-dark: #2e81b9;
$color-warning: #faebd7;
$color-border-gray: #BDC3C7;
$color-bg-yellow: #F1C40F;
$color-blue-tint: #D4E6F1;
$color-light-blue: #91C8E7;
$color-Blue-Tint1: #3BACC0;
$color-Blue-Tint2: #6CC1D0;
$color-purple-shade1: #7C4792;
$color-red-tint: #FAD6DE;
$color-cobalt-tint: #5C849C;
$color-cobalt: #2B3E49;
$color-sky-blue: #1598DB;
$color-tint-sky-blue: #D1EDFB;
$color-tint3-light-grey: #E3EBEF;
$color-tint-super-orange: #FAC4B6;
$color-cobalt-tint-grey: #F1F5F7;
$color-functional-red: #C91111;
$color-tint-violet: #633DAA;
$color-tint-green: #305d19;
$color-button-disabled: #50555C;
$color-chat-bg: #A5D1DB;
$color-bad-green: #769C49;

// roy-foss color
$color-royfoss-black: #1A1C20;
$color-royfoss-text-dark-grey: #393839;
$color-royfoss-mid-grey: #999999;
$color-royfoss-blue: #0064AC;
$color-royfoss-crimson-red: #E92126;
$color-royfoss-bg-light-grey: #F5F5F5;

// cell-com
$color-cellcom-orange: #D58919;
$color-cellcom-red: #C53A3A;
$color-cellcom-blue: #034895;
$color-cellcom-text-grey: #6D6F6F;
$color-cellcom-light-grey: #EEEEEE;
$color-cellcom-gray: #808080;

//mccarthy-color
$color-mccarthy-maroon: #991a30;

//New color variables (remove above ones after the completion of migration)
$color-light: #FFFFFF;
$color-dark: #000000;
$color-dark-high: rgba(0,0,0,.87);
$color-dark-medium: rgba(0,0,0,.6);
$color-dark-low: rgba(0,0,0,.38);
$color-grey-light: #ECEFF4;
$color-prime-white: #FAFAFA;
$color-bg-green: #1ABC9C;
$color-prime-red: #E74C3C;
$color-prime-gray: #F3F3F3;
$color-online-green: #769C49;
$color-offline-red: #C83B3B;
$color-active-blue: #255398;
$color-active-blue-select: #239EF0;
$color-active-blue-submenu: #D0DCEF;
$color-active-blue-light: #f5f7fa;
$color-light-border-gray: #D7D7D7;
$color-light-grey: #D3D3D3;
$color-board-bg: #E8EAF2;
$color-board-count: #767676;
$color-time-blue: #D5ECFE;
$color-time-blue-active: #1E293B;
$color-time-red: #FED5D5;
$color-time-red-active: #C83B3B;
$color-light-green: #DAF8E7;
$color-light-violet: #EBE7FF;
$color-button-dark: #1E293B;
$color-light-orange: #F8C38E;
$color-note-bg: #F6F8FA;
$color-control-active: #5bBE33;
$color-border-gray: #BDC3C7;
$color-bg-gray: #ECF0F1;
$color-tint3-light-grey: #E3EBEF;
$color-dark-green: #0AA17D;
$color-light-shade-green: #EFF8FA;
$color-button-blue: #147BB3;
$color-cobalt-tint3: #e3ebef;
$optimy-color: #D2360F;
$color-filter: #0070B8;
$color-gold-yellow: #EBBF0F;
$color-vanishing-blue: #D0DCEF;
$color-optimy-orange: #f04e22;
$color-dark-spring-green: #197E3F;
$color-light-yellow: #FBF3A0;
$color-pale-orange: #FAF0DC;
$color-chat-text: #0B3048;
$color-voice-invite: #499C60;
$color-video-invite: #256198;
$color-carousel: #002B5F;
$carousel-border:#c6c6c6;
$carousel-bg: #eaeaea;
$carousel-text-color:#242021;

//SPACING
$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-sm: 16px;
$spacing-md: 24px;
$spacing-lg: 32px;
$spacing-xl: 40px;
$spacing-xxl: 48px;

// LINE HEIGHT
$line-height-xxl: 2;
$line-height-xl: 1.8;
$line-height-lg: 1.6;
$line-height-md: 1.4;
$line-height: 1.5;
$line-height-sm: 1.3;
$line-height-xs: 1.2;
$line-height-trim: 1;

// FONT WEIGHT
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-normal-bold: 600;
$font-weight-semi-bold: 500;
$font-weight-bolder: 700;
$font-weight-bold: 900;

// FONT SIZE
$font-size-xxxl: 4.8rem;
$font-size-xxl-xl: 4.4rem;
$font-size-xxl-lg: 4.0rem;
$font-size-xxl: 3.6rem;
$font-size-xl: 3.2rem;
$font-size-lg: 2.4rem;
$font-size-md-regular: 2.2rem;
$font-size-md: 1.8rem;
$font-size-sm: 1.6rem;
$font-size-xs: 1.4rem;
$font-size-xxs-medium: 1.3rem;
$font-size-xxs: 1.2rem;
$font-size-xxs-small: 1.1rem;
$font-size-xxxs: 1rem;


$font-size-20: 1.2rem;
$font-size-30: 1.6rem;
$font-size-40: 2rem;
$font-size-50: 2.4rem;
$font-size-60: 3rem;

//border-radius

$border-radius-large: 18px;
$border-radius-medium: 8px;
$border-radius-small: 4px;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xxs: 0,
  xs: 400,
  sm: 600px,
  md: 900px,
  lg: 1200px,
  xl: 1500px,
  xxl: 1800px
) !default;

// Image Path
$img-path: "~/assets/images";
$image-path: '~src/assets/images/icons/';
$new-image-path: '~src/assets/images/new-icons/';

// Max Z-index
$max-z-index: 40;

//Default

$base-font-size: 10px;
$default-font-color: $color-text-dark;
$default-font-size: $font-size-sm;
$default-line-height: $line-height-md;
$default-font-weight: $font-weight-normal;
$default-font-family: $font-family-sans-serif;
$input-border-radius: $border-radius-large;
$input-default-height: $spacing-xl;
$section-spacing: $spacing-lg;
$default-body-color: $color-light-gray;

// optimy variables
$header-height: 120px;
