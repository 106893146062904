@import 'src/styles/variables';

.ngb-dp-day{
  .btn-light{
    color: $color-dark;
    background-color: $color-border-gray;
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-weight: $font-weight-semi-bold;
    &:hover,
    &:focus,
    &:active{
      background-color: $color-blue !important;
      color: $color-light;
    }
  }
  .bg-primary{
    background-color: $color-blue !important;
  }
  &.ngb-dp-today {
    .btn-light {
      background-color: $color-border-gray;
    }
  }
}
ngb-datepicker{
  width: 250px;
  height: auto;
  padding-bottom: 10px;
  border:1px solid $color-border-gray !important;
}
.ngb-dp-header{
  padding:8px 0 8px 0 !important;
  background-color: $color-light !important;
}
.ngb-dp-month-name{
  background-color: $color-light !important;
  color: $color-dark !important;
}
.ngb-dp-month{
  width: 100%;
}
.ngb-dp-weekdays{
  background-color: $color-light !important;
  color: $color-dark !important;
  .ngb-dp-weekday{
    color: $color-dark !important;
  }
}
.ngb-dp-week{
  justify-content: space-around;
  padding: 6px 0;
}
.ngb-dp-weekday{
  color: $color-border-gray !important;
  font-weight: 500;
  font-size: 12px;
}
.ngb-dp-weekdays{
  display: flex;
  justify-content: space-around;
}
.ngb-dp-month-name{
  color: $color-border-gray;
  font-weight: $font-weight-semi-bold;
}
.ngb-dp-arrow-btn{
  color: $color-dark;
}

.ngb-tp-input-container {
  .ngb-tp-input {
    height: 40px;
    border-radius: 0;
    font-size: $font-size-xs;
  }
  button {
    color: $color-text-light;
    &:hover, &:active, &:focus {
      color: $color-text-light;
    }
  }
}

app-create-client-request {
  ngb-datepicker{
    width: 233px;
    height: 264px;
  }
}

app-vva-date-time {
  .from-panel {
    ngb-datepicker {
      width: 235px;
    }
  }
  ngb-datepicker {
    position: absolute;
    top: 64px;
    left: 0;
    z-index: 1;
    background: $color-light;
  }
}
