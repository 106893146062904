@import 'src/styles/variables';

.invite__guest {
  .tag-field {
    .tag-wrapper {
      delete-icon {
        display: flex;
        align-items: center;
        svg {
          height: 16px;
        }
        &:hover {
          transform: none;
        }
      }
    }
    tag-input {
      background-color: #fff;
      border-radius: 8px;
      .ng2-tag-input {
        padding: 10px;
        min-height: 80px;
        border-radius: 8px;
        border: 1px solid $color-border-gray;
        font-size: 13px;
        font-weight: $font-weight-semi-bold;
        &:focus-within {
          min-height: 140px;
        }
        &.ng2-tag-input--invalid {
          border: 1px solid $color-crimson-red;
        }
        tag {
          background: transparent;
          border: 1px solid $color-border-gray;
          color: $color-cobalt;
          height: auto;
          padding: 2px 10px;
          font-weight: $font-weight-semi-bold;
        }
        tag-input-form,
        tag-input-form input{
          width: 100%;
          color: $color-cobalt;
          font-weight: $font-weight-semi-bold;
          font-size: $font-size-sm;
        }
      }
      .error-messages {
        font-size: $font-size-sm;
      }
    }
    &.error {
      tag-input {
        .ng2-tag-input {
          border: 1px solid $color-crimson-red;
          tag-input-form {
            .handleError {
              display: none;
            }
          }
          .ng2-tags-container {
            tag:last-of-type {
              color: $color-light;
              border: 1px solid $color-crimson-red;
              background: $color-crimson-red;
              svg{
                path {
                  fill: $color-light;
                }
              }
            }
          }
        }
      }
    }
  }
}
