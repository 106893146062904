
.call-container {
  &.call-on-card, &.call-on-float {
    .controls-container {
      height: 62px;
      border-radius: 8px;
      .control-button  {
        width: 30px;
        height: 30px;
      }
    }
    .participant-row {
      border-radius: 6px;
      .participant {
        //visibility: hidden;
        border-radius: 8px;
        &.maximizedParticipant {
          visibility: visible;
        }
        &.dominant-speaker {
          visibility: visible;
        }
        &.maximizedParticipant {
          border-radius: 8px;
        }
      }
    }
    .participant-row .participant.maximizedParticipant.mic-off.agent::after,
    .participant-row .participant.maximizedParticipant.mic-off.guest::after {
      width: 30px;
      height: 30px;
    }
    .participant-row .participant.maximizedParticipant::before {
      top: 10px;
      left: 50%;
      right: unset;
      transform: translateX(-50%);
    }
  }
}

