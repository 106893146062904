@import 'src/styles/variables';

.video-call {
  .participant-row {
    .participant {
      position: relative;
      width: 100px;
      height: 100px;
      border-radius: 8px;
      margin-left: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: $color-dark;
      border: 1px solid $color-light;
      cursor: pointer;
      z-index: 2;
      &.video-off {
        &:not(.mic-off) {
          background-image: url('#{$new-image-path}speaker.svg');
          background-size: 40px;
        }
      }
      &::after {
        position: absolute;
        content: "";
        bottom: 2px;
        left: 2px;
        background-image: url('#{$image-path}mic-on-icon.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        width: 16px;
        height: 16px;
        z-index: 10;
      }
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 2px 5px;
        content: attr(data-identity);
        color: $color-light;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-transform: capitalize;
        text-align: right;
        z-index: 2;
        border-radius: 8px;
        font-size: $font-size-xxs;
        background-color: rgba(0,0,0,0.2);
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
      &.guest:not(.maximizedParticipant), &.agent:not(.maximizedParticipant) {
        &.mic-off {
          &::after {
            background-image: url('#{$image-path}mic-off-icon.svg');
          }
        }
      }
      &.maximizedParticipant {
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        border-radius: 0;
        border: none;
        transition: 1s;
        position: absolute;
        z-index: 1;
        margin: 0;
        &.video-off {
          &:not(.mic-off) {
            background-size: 145px;
            //background-position: 50% 35%;
          }
        }
        video {
          border-radius: 0;
        }
        &::before {
          top: 16px;
          left: 16px;
          border-radius: 32px;
          width: fit-content;
          height: fit-content;
          bottom: inherit;
          font-size: $font-size-lg;
          padding: 4px 15px 4px 36px;
        }
        &::after {
          left: 22px;
          top: 28px;
          width: 20px;
          height: 20px;
        }
        &.mic-off {
          &::after {
            background-image: url('#{$image-path}mic-off-icon.svg');
          }
        }
      }
    }
  }
  &.mobile {
    .participant-row {
      &.participant-one {
        .participant {
          &:not(.maximizedParticipant) {
            width: 118px;
            height: 170px;
            border-radius: 8px;
          }
        }
      }
      &.more-participant {
        flex-direction: column-reverse !important;
        align-items: flex-end;
        .participant {
          &:not(.maximizedParticipant) {
            width: 118px;
            height: 106px;
          }
        }
      }
      .participant {
        margin-left: 0;
        border-radius: 0;
        &.mic-off {
          &.video-off {
            display: none;
          }
        }
      }
    }
  }
}
