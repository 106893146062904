@import 'src/styles/variables';

.cam-preview{
  video {
    width: 100%;
    object-fit: contain;
  }
}

.video-container {
  &.header-two-line {
    .video__header {
      height: 96px;
      align-items: flex-start;
      flex-direction: column;
      .queue-name {
        width: auto;
      }
    }
    .chat-container {
      height: calc(100vh - 170px);
    }
    .people-container {
      height: calc(100vh - 170px);
    }
    .video__content {
      padding: 96px 0 75px;
      iframe {
        max-height: calc(100vh - 172px);
        min-height: calc(100vh - 172px);
      }
      #agentShare {
        min-height: calc(100vh - 172px);
      }
    }
    .one-participant {
      .participant {
        max-height: calc(100vh - 170px);
      }
    }
    .two-participant {
      .participant {
        max-height: calc(100vh - 170px);
      }
    }
    .multiple {
      .participant {
        max-height: calc((100vh - 170px) / 2);
      }
    }
  }
  .video__content {
    #agentShare {
      width: 100%;
    }
  }
}
