@import "variables";
$image-path: "~src/assets/images/icons/";

.btn {
  cursor: pointer;
  display: inline-block;
  border-radius: 18px;
  border-width: 1px;
  border-style: solid;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  padding: #{$spacing-xxs} $spacing-sm;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  outline: none;
  &:disabled {
    cursor: not-allowed;
  }
  &:hover,
  &:focus,
  &:active {
    outline: none;
    color: $color-light;
    box-shadow: none;
  }
}

.btn-square {
  border-radius: 4px;
}

.btn-blue {
  color: $color-light;
  background-color: $color-blue;
  &:hover {
    background-color: $color-blue-dark;
  }
  &:active {
    background-color: $color-blue;
  }
}

.btn-prime-blue {
  color: $color-light;
  background-color: $color-prime-blue;
}

.btn-normal-green {
  color: $color-light;
  background-color: $color-bg-green;
}

.btn-prime-green {
  color: $color-light;
  background-color: $color-prime-green;
}

.btn-prime-red {
  color: $color-light;
  background-color: $color-prime-red;
}

.btn-prime-purple {
  color: $color-light;
  background-color: $color-prime-purple;
}

.btn-prime-gray {
  color: $color-light;
  background-color: $color-text-gray;
}

.btn-brand-red {
  color: $color-light;
  background-color: $color-brand-red;
}

.btn-text-light {
  color: $color-light;
  background-color: $color-text-light;
}

.btn-prime-orange {
  color: $color-light;
  background-color: $color-prime-orange;
}

.btn-ski-blue {
  color: $color-light;
  background-color: $color-prime-blue;
  border-radius: 4px;
}

.btn-functional-red {
  color: $color-light;
  background-color: $color-functional-red;
}

.btn-trans-green {
  color: $color-bg-green;
  border: 1px solid $color-bg-green;
  &:hover,
  &:active,
  &:focus {
    color: $color-bg-green;
  }
}

.btn-trans-red {
  color: $color-prime-red;
  border: 1px solid $color-prime-red;
  &:hover,
  &:active,
  &:focus {
    color: $color-prime-red;
  }
}

.btn-trans-yellow {
  color: $color-prime-yellow;
  border: 1px solid $color-prime-yellow;
  &:hover,
  &:active,
  &:focus {
    color: $color-prime-yellow;
  }
}

.btn-trans-blue {
  color: $color-prime-blue;
  border: 1px solid $color-prime-blue;
  &:hover,
  &:active,
  &:focus {
    color: $color-prime-blue;
  }
}

.btn-trans-black {
  color: $color-dark;
  border: 1px solid $color-dark;
  &:hover,
  &:active,
  &:focus {
    color: $color-dark;
  }
}

.btn-trans-gray {
  color: $color-text-gray;
  border: 1px solid $color-text-gray;
  &:hover,
  &:active,
  &:focus {
    color: $color-text-gray;
  }
}

.btn-trans-shade-red {
  color: $color-red-shade;
  border: 1px solid $color-red-shade;
  &:hover,
  &:active,
  &.active {
    color: $color-light;
    background-color: $color-red-shade;
  }
}

.btn-trans-tint-blue {
  color: $color-blue-tint;
  border: 1px solid $color-blue-tint;
  &:hover,
  &:active,
  &.active {
    color: $color-light;
    background-color: $color-blue-tint;
  }
}

.btn-transparent {
  color: $color-text-dark;
  background-color: transparent;
  border: 1px solid $color-text-light;

  &:hover,
  &:active,
  &:focus {
    color: $color-text-dark;
  }
}

.btn-trans-blue-tint2 {
  color: $color-Blue-Tint2;
  border: 1px solid $color-Blue-Tint2;
  &:hover,
  &:active,
  &:focus {
    color: $color-Blue-Tint2;
  }
}

.btn-trans-gray-tint {
  color: $color-light-border-gray;
  background-color: transparent;
  border: 1px solid $color-light-border-gray;
  &:hover,
  &:active {
    color: $color-text-dark;
    background-color: $color-light-border-gray;
  }
}

.modal-primary-btn {
  color: $color-light;
  background-color: $color-bg-green;
}
.modal-secondary-btn {
  color: $color-text-dark;
  background-color: transparent;
  border: 1px solid $color-text-light;

  &:hover,
  &:active,
  &:focus {
    color: $color-text-dark;
  }
}

.btn-radio {
  color: $color-text-light;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  margin: 0;
  padding-left: 50px;
  background: transparent url("#{$image-path}radio.svg") 0 50% no-repeat;
  transition: none;
  flex: none;
  border-width: 0;
  &.active {
    margin-left: 0;
    background: transparent url("#{$image-path}radio-active.svg") 5px 50%
      no-repeat;
  }
  &:active,
  &:focus,
  &:hover {
    color: $color-text-light;
    outline: none;
    box-shadow: none;
    margin: 0;
    transition: none;
    border-width: 0;
  }
}

.white-border {
  border: 8px solid $color-light;
  border-radius: 32px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05), -2px -2px 4px $color-light;
  &:active,
  &:focus,
  &:hover {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05), -2px -2px 4px $color-light;
  }
}

// Buttons that are going to be using the client colors
.btn-op-primary {
  color: $color-light;
  background-color: var(--button-color);
  border-color: var(--button-color);
  &:hover,
  &:focus,
  &:active {
    &:not(.disabled) {
      color: $color-light;
      background-color: var(--button-color);
      border-color: var(--button-color);
    }
  }
}

.btn-link {
  color: var(--button-color);
  background-color: transparent;
  border-color: transparent;
  &:hover,
  &:focus,
  &:active {
    &:not(.disabled) {
      color: var(--button-color);
      background-color: transparent;
      border-color: transparent;
    }
  }
}

.op-btn {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-xs;
  font-family: $font-family-sans-serif;
  font-weight: 400;
  line-height: 32px;
  min-height: 32px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0 16px;
  text-align: center;
  text-decoration: none;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out,
  border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  user-select: none;
  vertical-align: baseline;
  &:focus {
    outline: none;
  }
  &-stroked {
    border-color: $button-color;
    color: $button-color;
    background-color: transparent;
    &:hover:not(:disabled),
    &.hover:not(:disabled) {
      color: $color-light;
      background-color: $button-color;
    }
    &:active:not(:disabled),
    &.active:not(:disabled) {
      color: $color-light;
      border-color: $button-color;
      background-color: $button-color;
    }
    &:disabled {
      color: $button-color;
      border-color: $button-color;
      opacity: .8;
      cursor: not-allowed;
    }
  }
  &-flat {
    border-color: $button-color;
    color: $color-light;
    background-color: $button-color;
    &:hover:not(:disabled),
    &.hover:not(:disabled) {
      color: $color-light;
      background-color: $button-color;
    }
    &:active:not(:disabled),
    &.active:not(:disabled) {
      color: $color-light;
      border-color: $button-color;
      background-color: $button-color;
    }
    &:disabled {
      color: $color-light;
      border-color: $button-color;
      opacity: .8;
      cursor: not-allowed;
    }
  }

  &-danger {
    border-color: $color-crimson-red;
    color: $color-light;
    background-color: $color-crimson-red;
    &:hover:not(:disabled),
    &.hover:not(:disabled) {
      color: $color-light;
      background-color: $color-crimson-red;
    }
    &:active:not(:disabled),
    &.active:not(:disabled) {
      color: $color-light;
      border-color: $color-crimson-red;
      background-color: $color-crimson-red;
    }
    &:disabled {
      color: $color-light;
      border-color: $color-crimson-red;
      opacity: .8;
      cursor: not-allowed;
    }
  }
}
