
/*-------padding class---------*/
.op-p-16 {
  padding: 16px !important;
}
.op-py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.op-px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.op-pt-16 {
  padding-top: 16px !important;
}
.op-pb-16 {
  padding-bottom: 16px !important;
}
.op-pl-16 {
  padding-left: 16px !important;
}
.op-pr-16 {
  padding-right: 16px !important;
}

.op-p-24 {
  padding: 24px !important;
}
.op-py-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.op-px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.op-pt-24 {
  padding-top: 24px !important;
}
.op-pb-24 {
  padding-bottom: 24px !important;
}
.op-pl-24 {
  padding-left: 24px !important;
}
.op-pr-24 {
  padding-right: 24px !important;
}

.op-p-8 {
  padding: 8px !important;
}
.op-py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.op-px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.op-pt-8 {
  padding-top: 8px !important;
}
.op-pb-8 {
  padding-bottom: 8px !important;
}
.op-pl-8 {
  padding-left: 8px !important;
}
.op-pr-8 {
  padding-right: 8px !important;
}

/*-------margin Class-----------*/
.op-m-16 {
  margin: 16px !important;
}
.op-my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.op-mx-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}
.op-mt-16 {
  margin-top: 16px !important;
}
.op-mb-16 {
  margin-bottom: 16px !important;
}
.op-ml-16 {
  margin-left: 16px !important;
}
.op-mr-16 {
  margin-right: 16px !important;
}

.op-m-24 {
  margin: 24px !important;
}
.op-my-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.op-mx-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}
.op-mt-24 {
  margin-top: 24px !important;
}
.op-mb-24 {
  margin-bottom: 24px !important;
}
.op-ml-24 {
  margin-left: 24px !important;
}
.op-mr-24 {
  margin-right: 24px !important;
}

.op-m-8 {
  margin: 8px !important;
}
.op-my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.op-mx-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.op-mt-8 {
  margin-top: 8px !important;
}
.op-mb-8 {
  margin-bottom: 8px !important;
}
.op-ml-8 {
  margin-left: 8px !important;
}
.op-mr-8 {
  margin-right: 8px !important;
}
.op-ml-4 {
  margin-left: 4px !important;
}
