@import "src/styles/variables";

#mobile-container {
  .queue-board {
    padding-top: 0;
    .queue-card {
      .start-call {
        height: 42px;
      }
    }
  }
  .call-mobile-video {
    .controls-container {
      .controls-div {
        justify-content: space-evenly;
      }
    }
    .call-container {
      .video-call {
        .participant-row {
          align-items: flex-end;
          padding-bottom: 80px;
        }
      }
    }
  }
}

#call-mobile {
  .call-container {
    width: 100vw;
    height: calc(var(--app-height));
    top: 0;
    .room-bar-on {
      top: 0;
      width: 100vw;
      height: calc(var(--app-height));
    }
  }
  .chat-body {
    padding: 0 16px 16px;
  }
  .chat-body , .chat-panel{
    background-color: transparent;
    &.call-on {
      transition: 0.8s;
      height: 100%;
    }
  }
  .end-popover {
    background-color: $color-dark;
    color: $color-light;
    padding: 16px;
    border: 1px solid $color-text-gray;
    border-radius: 0;
    .popover-body {
      padding: 0;
      border-radius: 0;
    }
    .arrow {
      display: none;
    }
    button {
      font-weight: $font-weight-semi-bold;
    }
  }
}
