@import 'src/styles/variables';
@import "src/styles/mixins";

.cdk-overlay-container {
  z-index: 1070;
}
.mat-mdc-menu-panel {
  .mat-mdc-menu-content {
    .profile-button {
      padding: 0 24px;
      font-size: $font-size-xs;
      &:focus {
        outline: none;
      }
      svg-icon, svg {
        width: 20px;
        height: 20px;
      }
      span {
        font-size: $font-size-xs;
        color: $color-dark-medium;
      }
      &:last-child {
        border-top: 1px solid $color-dark-low;
      }
      svg-icon {
        margin-right: 20px;
      }
      &.action-option {
        padding: 0 16px;
        svg-icon, svg {
          width: 18px;
          height: 18px;
          path{
            fill: $color-button-dark;
          }
        }
        span{
          color: $color-button-dark;
        }
        &:last-child{
          border-top: none;
        }
        &:hover, &.icon-active{
          @include _hover('background', 216 , 0.15);
          svg-icon svg path{
            fill: $color-active-blue;
          }
          span{
            color: $color-active-blue;
          }
        }
        &.icon-active{
          @include _active('background', 216 , 0.15);
        }
      }
    }
    .search-lang {
      padding: 10px;
      input {
        padding-left: 10px;
        width: 100%;
        border: none;
        outline-style: none;
        height: 40px;
        border-radius: 4px;
        border: 1px solid $color-border-gray;
        &::placeholder{
          color: $color-dark-low;
        }
      }
    }
  }
}

.desktop-container {
  .content-div {
    .nav-menu {
      width: 213px;

    }
  }
  &.minimize-view {
    .side-nav {
      &:not(.hover) {
        .content-div {
          .nav-menu {
            .menu {
              width: 52px;
            }
          }
        }
      }
    }
  }
}

.toolbar {
  .mat-toolbar.mat-primary {
    background-color: $color-light;
    color: $color-dark-high;
  }
}

.overflow-menu-footer {
  .mat-list-base .mat-list-item .mat-list-item-content {
    padding: 0;
  }
  .mat-mdc-list-item {
    padding: 0 4px;
    &.mdc-list-item--with-one-line {
      height: 56px;
    }
    .mdc-list-item:focus::before, .mdc-list-item:hover::before{
      background-color: transparent;
    }
    .mdc-list-item__secondary-text {
      font-size: $font-size-sm;
      color: $color-dark-high;
    }
    svg-icon {
      padding: 1px;
      height: 24px;
      width: 24px;
      margin-right: 16px;
    }
  }
}
.overflow-menu {
  transform: rotate(90deg);
  svg {
    path {
      fill: $color-light;
    }
  }
  .mat-list-base .mat-list-item .mat-list-item-content {
    padding: 0;
  }
  .mat-mdc-list-item {
    padding: 0 4px;
    &.mdc-list-item--with-one-line {
      height: 56px;
    }
    .mdc-list-item:focus::before, .mdc-list-item:hover::before{
      background-color: transparent;
    }
    .mdc-list-item__secondary-text {
      font-size: $font-size-sm;
      color: $color-dark-high;
    }
    svg-icon {
      padding: 1px;
      height: 24px;
      width: 24px;
      margin-right: 16px;
    }
  }
}

.queue-container {
  .mat-drawer-container {
    background-color: transparent;
  }
  .mat-drawer-content {
    overflow: hidden;
  }
}

.footer-div {
  .mat-button-toggle-group {
    border-radius: 16px 16px 0 0;
    border: none;
    background-color: #255398;
    .mat-button-toggle {
      width: calc(100vw / 3);
      height: 56px;
      background-color: $color-active-blue;
      border: none;
      border-left-width: 0 !important;
      .mat-button-toggle-button {
        width: calc(100vw / 3);
        height: 56px;
        color: $color-light;
        outline: none;
        .mat-button-toggle-label-content {
          svg-icon {
            width: 20px;
            height: 20px;
            display: flex;
            svg {
              width: 20px;
              height: 20px;
              path {
                stroke: $color-light;
                fill: $color-light;
              }
            }
          }
          span {
            height: 16px;
            line-height: 16px;
            margin-top: 5px;
          }
        }
      }
      &:first-child {
        .mat-button-toggle-button {
          border-radius: 16px 0 0;
        }
      }
      &:last-child {
        .mat-button-toggle-button {
          border-radius: 0 16px 0 0;
        }
      }
      &.mat-button-toggle-checked {
        background-color: $color-active-blue-select;
      }
    }
  }
}

.mobile-queue-filter, .assign-agent-list {
  .mat-mdc-checkbox {
    .mdc-checkbox {
      width: 24px;
      height: 24px;
      margin-right: 24px;
      .mdc-checkbox__background {
        width: 24px;
        height: 24px;
        border-radius: 16px;
      }
      .mdc-checkbox__ripple {
        width: 46px;
        height: 46px;
      }
      .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
        border-color: $color-active-blue;
        background-color: $color-active-blue;
      }
      &:not(:disabled):active .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
        border-color: $color-active-blue;
        background-color: $color-active-blue;
      }
      &:hover {
        .mdc-checkbox__native-control:checked~.mdc-checkbox__ripple {
          background-color: $color-active-blue;
        }
      }
    }
  }
}

.assign-agent-list {
  .mat-checkbox {
    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        margin-right: 0;
      }
    }
  }
}

.desktop-queue-header, .auto-dashboard-header, .notification-nav-links {
  display: flex;
  .mat-mdc-tab-link-container {
    border-bottom: 1px solid $color-border-gray;
    .mat-mdc-tab-link {
      padding: 0 16px;
      min-width: 100px;
      color: $color-dark-medium;
      opacity: 1;
      align-items: center;
      &:hover {
        text-decoration: none;
      }
      &.mdc-tab--active {
        .mdc-tab__text-label {
          color: $color-active-blue;
        }
      }
    }
    .mat-ink-bar {
      background-color: $color-active-blue;
    }
  }
}

.guest-panel, .participants-panel{
  .mat-mdc-tab-label-container {
    .mat-tab-label {
      padding: 0 16px;
      min-width: 100px;
      opacity: 1;
      font-size: $font-size-sm;
      &.mat-tab-label-active {
        color: $color-active-blue;
      }
    }
    .mat-ink-bar {
      background-color: $color-active-blue;
    }
  }
}

.participants-panel, .client-info-panel {
  .mdc-tab {
    padding: 0;
    height: 56px !important;
  }
  .mat-accordion {
    .mat-expansion-panel {
      border-bottom: 1px solid $color-grey-light;
      .mat-expansion-panel-header {
        font-weight: $font-weight-semi-bold;
        .mat-expansion-panel-header-title {
          font-size: $font-size-sm;
        }
      }
      .mat-expansion-indicator {
        color: $color-active-blue;
        &::after {
          color: $color-active-blue;
        }
      }
      &.mat-expansion-panel-spacing {
        margin: 0;
        border-radius: 0;
      }
      &:last-of-type {
        border-radius: 0;
      }
    }
  }
  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    fill: $color-active-blue;
    svg-icon svg path {
      fill: $color-active-blue;
    }
  }
}

.client-info-panel{
  .mat-form-field .mat-form-field-wrapper{
    height: 35px;
    .mat-form-field-flex {
      padding: 0 5px 0 10px;
    }
  }
  .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
    font-size: 13px;
    font-weight: 400;
  }
  &.drawer-opened {
    .mat-drawer-content {
      height: 420px;
    }
  }
}

.participants-panel{
  .mat-mdc-tab-group{
    height:calc(100% - 95px);
    .mat-mdc-tab-body-wrapper{
      height:calc(100% - 56px);
      .client-info-panel {
        .mat-drawer-container{
          height: calc(100% - 193px);
        }
      }
      .mat-drawer-container{
        height:100%;
        .mat-drawer{
          width: 100%;
        }
      }
    }
  }
  .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    display: none;
  }
}

.invite-drawer-container{
  .agent-invite-checkbox{
    height: 28px;
    .mat-checkbox-inner-container{
      width: 28px;
      height: 28px;
    }
    .mat-checkbox-frame, .mat-checkbox-background{
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border-width: 1.5px;
    }
    &.mat-checkbox-checked .mat-checkbox-background{
      background-color: $color-active-blue;
    }
  }
  .guest-panel{
    .mat-chip-remove{
      border: none;
      .mat-icon{
        position: absolute;
        right:4px;
        bottom: 5px;
      }
    }
    .mat-form-field-wrapper{
      min-height: 70px;
      .chip-error {
        background: $color-text-red;
        color: $color-light;
      }
    }
  }
}

/*-----------MAT SELECT DESIGN----------------*/
.mat-mdc-form-field {
  .mat-mdc-text-field-wrapper {
    border: 1px solid $color-light-grey;
    border-radius: 4px;
    padding: 0 10px;
    background-color: transparent;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }
  .mdc-line-ripple {
    display: none;
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mat-mdc-form-field-infix {
    min-height: 45px;
  }
  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .mat-mdc-form-field-focus-overlay {
    background: transparent;
  }
}

.notification-settings{
  .notification-nav-links{
    .mat-tab-header{
      width: 100%;
    }
  }
  .mat-slide-toggle-bar{
    height: 20px;
    border-radius: 12px;
    width:35px;
    .mat-slide-toggle-thumb-container{
      top: 0;
    }
  }
  .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after,
  .mdc-switch:enabled .mdc-switch__track::after,
  .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after,
  .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after,
  .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after,
  .mdc-switch.mdc-switch--selected:enabled:focus:not(:active) .mdc-switch__handle::after{
    background-color: $color-optimy-orange;
  }
  .mat-mdc-form-field .mat-mdc-form-field-wrapper{
    max-width: 170px;
    padding: 6px 10px 10px 10px;
    .mat-form-field-infix {
      padding: 0;
    }
  }
}

.history-container {
  .mat-mdc-form-field .mat-mdc-text-field-wrapper{
    padding: 0;
    height: 40px;
    font-size: 13px;
    .mat-form-field-flex{
      padding: 0;
    }
    .mat-form-field-infix{
      padding: 11px;
      border-top: none;
    }
    .mat-select-arrow-wrapper{
      height: 0;
    }
    .agent-filter{
      .mat-mdc-select-trigger{
        &::before{
          content: url("#{$new-image-path}user-headset.svg");
          width: 20px;
          height: 18px;
          margin-right: 5px;
        }
        .mat-select-value{
          margin-left: 8px;
        }
      }
    }
  }
  &.mobile {
    .mat-form-field .mat-form-field-wrapper .mat-form-field-infix{
      width: 178px;
    }
  }
  .mat-mdc-form-field .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding: 5px 10px;
    min-height: 36px;
    .mat-mdc-select-value-text {
      font-size: 13px;
    }
  }
}

app-create-client-request {
  .mat-mdc-form-field .mat-mdc-text-field-wrapper {
    padding: 8px;
    height: 40px;
    font-size: 13px;
  }
  .mat-mdc-form-field .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 0px;
  }
  .mat-mdc-select{
    font-size: $font-size-xxs-medium;
  }
  mat-checkbox{
    .mdc-form-field {
      height: 30px;
    }
    label {
      margin-bottom: 0;
      font-size: $font-size-xxs-medium;
    }
  }
}

app-create-client-request {
  mat-checkbox{
    label {
      margin-bottom: 0;
      font-size: $font-size-xxs-medium;
    }
  }
}

.auto-dashboard-container {
  .mat-form-field {
    &.time-range {
      padding-left: 16px;
    }
  }
  &.mobile {
    .mat-form-field {
      margin-bottom: 10px;
      &.time-range {
        padding-left: 0;
      }
    }
  }
}

.mat-mdc-select-panel {
  .tenant{
    padding: 0 16px;
    width: 100%;
    &-name{
      min-width: 33%;
      padding: 0 5px;
      font-size: 11px;
      font-weight: $font-weight-semi-bold;
    }
    &-border{
      border-bottom: 1px solid $color-border-gray;
      min-width: 33%;
    }
  }
  .mat-mdc-option{
    font-size: 13px;
    width: 100%;
    &.common-option {
      &:hover{
        background-color: $color-active-blue;
        color: $color-light;
      }
      &.mdc-list-item--selected {
        &:hover{
          background-color: $color-active-blue;
          color: $color-light;
        }
      }
    }
    &.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
      background-color: $color-active-blue;
      color: $color-light;
      .mdc-list-item__primary-text {
        color: $color-light;
      }
    }
  }
  .avatar {
    position: absolute;;
    left: 20px;
    z-index: 1;
  }
  .agent-val {
    padding-left: 60px
  }
  .agent-name{
    position: relative;
    .common-option .mat-option-text{
      margin-left: 35px;
    }
  }
  .agent-search{
    width: calc(100% - 16px);
    height: 48px;
    border-radius: 4px;
    border: 1px solid $color-border-gray;
    padding: 9px;
    position: relative;
    margin: 8px;
    display: flex;
    align-items: center;
    input {
      border: none;
      outline-style: none;
      font-size: $font-size-xxs;
      &::placeholder{
        color: $color-dark-low;
      }
      &#agentSearch{
        background-image: url("#{$new-image-path}search-text.svg");
        background-repeat: no-repeat;
        text-indent: 20px;
        &:focus{
          background-image:none;
          text-indent:0;
        }
      }
    }
  }
}

body {
  .modal-backdrop {
    z-index: 1050 !important;
  }
}

.translate-modal-mobile {
  .translate-modal-div {
    .mat-mdc-form-field {
      background-color: $color-light;
    }
    .mat-mdc-checkbox {
      label {
       color: $color-light;
      }
    }
  }
}
.translate-modal-div {
  .mat-mdc-form-field {
    width: 100%;
  }
  .mat-mdc-checkbox {
    label {
      margin-bottom: 0;
    }
  }
  .mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    background-color: $color-light;
  }
  .mdc-checkbox:active .mdc-checkbox__native-control:checked~.mdc-checkbox__ripple {
    background-color: $color-active-blue;
  }
  .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background ,
  .mdc-checkbox:hover .mdc-checkbox__native-control:checked~.mdc-checkbox__ripple{
    background-color: $color-active-blue !important;
    border-color: $color-active-blue !important;
  }
  .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    background-color: $color-active-blue !important;
    border-color: $color-active-blue !important;
  }
}

.mat-mdc-menu-content {
    .search-lang {
      padding: 10px;
      input {
        border: none;
        outline-style: none;
        font-size: $font-size-xxs;
        height: 40px;
        border-radius: 4px;
        border: 1px solid $color-border-gray;
        &::placeholder{
          color: $color-dark-low;
        }
      }
    }
  }
