@import "src/styles/variables";

.message-cont {
  .message {
    pre {
      p {
        overflow-wrap: break-word;
        margin: 0;
        white-space: pre-line;
        display: contents;
      }
      h4 {
        margin-bottom: 6px;
        font-weight: 600;
      }
      a {
        color: $color-sky-blue;
        text-decoration: underline;
      }
      img {
        width: 100%;
      }
    }
  }
}
.item-chat {
  &.mini-view {
    .message {
      .chat-message-container pre {
        color: $color-light !important;
      }
    }
  }
}

