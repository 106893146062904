@import "variables";
$image-path: '~src/assets/icons/';
$image-path: '~src/assets/images/icons/';

@mixin font-face($name, $url, $weight, $style, $display) {
  @font-face {
    font-family: $name;
    src: url($url) format('truetype');
    font-weight: $weight;
    font-style: $style;
    font-display: $display;
  }
}

@mixin for-desktop {
  @media (min-width: 1025px) { @content; }
}
@mixin for-tablet-lg {
  @media (max-width: 1024px) { @content; }
}
@mixin for-tablet {
  @media (max-width: 970px) { @content; }
}
@mixin for-mobile {
  @media (max-width: 840px) { @content; }
}
@mixin for-mobile-xs{
  @media (max-width: 767px) { @content; }
}
@mixin for-mobile-xxs{
  @media (max-width: 370px) { @content; }
}
@mixin for-desktop-xl {
  @media (min-width: 1280px) { @content; }
}
@mixin for-desktop-xxl {
  @media (min-width: 1500px) { @content; }
}
@mixin for-mobile-xs-above{
  @media (min-width: 768px) { @content; }
}
@mixin for-desktop-min{
  @media (min-width: 1025px) and (max-width: 1279px) { @content; }
}
@mixin for-mobile-md{
  @media (max-width: 414px) { @content; }
}
@mixin for-tablet-md{
  @media (min-width: 768px) and (max-width: 1024px) { @content; }
}
@mixin for-lg-tablet-desktop {
  @media (min-width: 1023px) { @content; }
}

@mixin track($fill: 0) {
  box-sizing: border-box;
  border: none;
  width: 100%; height: 10px;
  background: $color-light;
  border-radius: 8px;
  box-shadow: inset -2px -2px 4px $color-light, inset 2px 2px 4px rgba(0, 0, 0, 0.1);

  @if $fill == 1 {
    .js & {
      background: linear-gradient($color-prime-blue, $color-prime-blue)
      0/ var(--sx) 100% no-repeat $color-light
    }
  }
}

@mixin fill() {
  height: 10px;
  background: $color-prime-blue;
  box-shadow: inset -2px -2px 2px rgba(255, 255, 255, 0.15), inset 2px 2px 2px rgba(0, 0, 0, 0.15);;
}

@mixin thumb() {
  box-sizing: border-box;
  border: none;
  width: 30px; height: 40px;
  border-radius: 50%;
  cursor: pointer;
  background: url("#{$image-path}slider-thumb.svg") -5px center no-repeat;
}

@mixin fontStyle($size, $weight, $color){
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

@mixin arrow($color, $size, $direction){
  border: solid $color;
  border-width: 0 2px 2px 0;
  display: inline-block;
  width: $size;
  height: $size;
  transform: rotate($direction);
  -webkit-transform: rotate($direction);
}

@mixin _hover($property, $hue, $opacity: 1) {
  #{$property}: hsla($hue, 80%, 34%, $opacity);
}
@mixin _active($property, $hue, $opacity: 1) {
  #{$property}: hsla($hue, 77%, 44%, $opacity);
}
